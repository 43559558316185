<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    id="detalhes-produto"
    centered
    size="lg"
    hide-footer
    cancel-variant="default"
    @show="loadItens"
    @hide="clearDados"
  >
    <template slot="modal-header">
      <h4 class="modal-title">Dados do produto</h4>
      <a class="btn-close" @click="$bvModal.hide('detalhes-produto')"></a>
    </template>
    <template v-if="isLoad">
      <form @submit.prevent="onFormSubmit">
        <div class="row">
          <div class="col-12 mb-2">
            <label class="form-label">Nome:</label>
            <input
              ref="input"
              class="form-control"
              type="text"
              v-model="nome"
              required
            />
          </div>
          <div class="col-4 mb-2">
            <label class="form-label">Qtd.Caixa:</label>
            <input
              class="form-control"
              type="text"
              v-on:keypress="validarNumero"
              v-model="qtd_caixa"
              required
            />
          </div>
          <div class="col-4 mb-2">
            <label class="form-label">Qtd.Max Folhas:</label>
            <input
              class="form-control"
              type="text"
              v-on:keypress="validarNumero"
              v-model="qtd_max_folhas"
              required
            />
          </div>
          <div v-if="!ecarta" class="col-4 mb-2">
            <label class="form-label">Identificação Arquivo:</label>
            <input
              class="form-control"
              type="text"
              v-model="identificacao_arq"
            />
          </div>
          <div v-if="ecarta" class="col-4 mb-2">
            <label class="form-label d-flex justify-content-between">
              <span>Add matrizes:</span>
              <span @click="addMatriz" class="cursor-pointer">
                <i class="fa fa-save" /> Salvar
              </span>
            </label>
            <input
              class="form-control"
              type="text"
              v-model="matriz"
              v-on:keypress="validarNumero"
              :required="matrizes.length == 0"
            />
          </div>
          <div class="col-12 d-flex justify-content-between">
            <div class="option me-3">
              <div class="option-label">Blocado:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="blocado"
                  />
                  <label class="form-check-label"></label>
                </div>
              </div>
            </div>
            <div class="option me-3">
              <div class="option-label">Multivias:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="multivias"
                  />
                  <label class="form-check-label"></label>
                </div>
              </div>
            </div>
            <div class="option me-3">
              <div class="option-label">Duplex:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="duplex"
                  />
                  <label class="form-check-label"></label>
                </div>
              </div>
            </div>
            <div v-if="multivias" class="option me-3">
              <div class="option-label">Páginas Variáveis:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="paginas_variaveis"
                  />
                  <label class="form-check-label" />
                </div>
              </div>
            </div>
            <div class="option me-3">
              <div class="option-label">E-carta:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="ecarta"
                    v-on:click="e => checkEcarta(e)"
                  />
                  <label class="form-check-label" />
                </div>
              </div>
            </div>
            <div v-if="ecarta" class="option me-3">
              <div class="option-label">Arq. Complementar:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="arquivo_complementar"
                  />
                  <label class="form-check-label"></label>
                </div>
              </div>
            </div>
            <div v-if="ecarta" class="option me-3 text-center">
              <div class="option-label">Arq. Complementar Duplex:</div>
              <div class="option-input">
                <div
                  class="form-check form-switch d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="arquivo_complementar_duplex"
                  />
                  <label class="form-check-label"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2" v-if="matrizes.length > 0 && ecarta">
            <p class="m-0">Matrizes</p>
            <ul class="list-group">
              <li
                v-for="(item, i) in matrizes"
                :key="i"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>{{ item.matriz }}</span>
                <i
                  @click="removeItem(item.matriz)"
                  class="fa fa-close cursor-pointer"
                />
              </li>
            </ul>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-between mt-3">
          <!--        <a class="me-2 rounded btn btn-danger btn-sm" @click="$bvModal.hide('modalDialog')">-->
          <!--          Voltar-->
          <!--        </a>-->
          <a
            class="me-2 rounded btn btn-danger btn-sm"
            @click="$bvModal.hide('detalhes-produto')"
          >
            <i class="fa fa-close me-2" />Fechar
          </a>

          <button
            v-if="checkFuncionalidade(12)"
            :disabled="saving"
            type="submit"
            :class="
              produtoId > 0
                ? 'd-flex align-items-center me-2 rounded btn btn-warning btn-sm'
                : 'd-flex align-items-center me-2 rounded btn btn-success btn-sm'
            "
          >
            <b-spinner v-if="saving" small class="me-2" />
            <i :class="produtoId > 0 ? 'fa fa-edit me-2' : 'fa fa-save me-2'" />
            {{ produtoId > 0 ? 'Atualizar' : 'Salvar' }}
          </button>
        </div>
      </form>
      <notifications
        group="top-center"
        :duration="5000"
        :width="500"
        animation-name="v-fade-left"
        position="top center"
      />
    </template>
    <template v-else>
      <div class="loading" />
    </template>
  </b-modal>
</template>
<script>
import axios from 'axios'
import CheckFunc from '../../../../mixins/CheckFunc'

export default {
  name: 'ModalProduto',
  data() {
    return {
      isLoad: false,
      saving: false,
      nome: '',
      cod_produto: '',
      qtd_caixa: '',
      blocado: false,
      multivias: false,
      duplex: false,
      arquivo_complementar: false,
      arquivo_complementar_duplex: false,
      qtd_max_folhas: '',
      paginas_variaveis: false,
      ecarta: false,
      matrizes: [],
      matriz: '',
      identificacao_arq: null
    }
  },
  mixins: [CheckFunc],
  props: {
    produtoId: {
      default: undefined
    },
    cliente_id: {
      type: Number
    },
    reloadForm: {}
  },
  methods: {
    validarNumero(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    addMatriz() {
      let temp = this.matriz
      this.matrizes.push({
        matriz: temp
      })
      this.matriz = ''
    },
    removeItem(matriz) {
      this.matrizes = this.matrizes.filter(f => f.matriz !== matriz)
    },
    async loadItens() {
      this.isLoad = false
      if (this.produtoId === undefined) {
        this.isLoad = true
        return
      }
      this.isLoad = false

      await axios
        .get(`produto/${this.produtoId}`)
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.setDados(res)
          this.isLoad = true
        })
    },
    checkEcarta(e) {
      if (e.target.checked) {
        this.identificacao_arq = ''
      } else {
        this.matriz = ''
        this.removeItem()
        this.matrizes = []
      }
    },
    setDados(dado) {
      this.nome = dado.nome
      this.cod_produto = dado.cod_produto
      this.qtd_caixa = dado.qtd_caixa
      this.blocado = dado.blocado
      this.multivias = dado.multivias
      this.duplex = dado.duplex
      this.arquivo_complementar = dado.arquivo_complementar
      this.arquivo_complementar_duplex = dado.arquivo_complementar_duplex
      this.qtd_max_folhas = dado.qtd_max_folhas
      this.paginas_variaveis = dado.paginas_variaveis
      this.ecarta = dado.ecarta
      this.matrizes = dado.matrizes
      this.identificacao_arq = dado.identificacao_arq
    },
    clearDados() {
      this.nome = ''
      this.cod_produto = ''
      this.qtd_caixa = ''
      this.blocado = false
      this.multivias = false
      this.duplex = false
      this.arquivo_complementar = false
      this.arquivo_complementar_duplex = false
      this.qtd_max_folhas = ''
      this.paginas_variaveis = false
      this.ecarta = false
      this.matrizes = []
      this.matriz = ''
      this.identificacao_arq = ''
    },
    async onFormSubmit() {
      this.saving = true
      let data = {
        nome: this.nome,
        qtd_caixa: this.qtd_caixa,
        blocado: this.blocado,
        multivias: this.multivias,
        duplex: this.duplex,
        identificacao_arq: this.identificacao_arq,
        arquivo_complementar: this.arquivo_complementar,
        arquivo_complementar_duplex: this.arquivo_complementar_duplex,
        qtd_max_folhas: this.qtd_max_folhas,
        paginas_variaveis: this.paginas_variaveis,
        ecarta: this.ecarta,
        matrizes: this.matrizes.map(i => {
          return i.matriz
        }),
        cliente_id: this.cliente_id
      }

      let url = 'produto'
      let method = 'post'
      if (this.isEditing) {
        url = 'produto/' + this.produtoId
        method = 'put'
      }
      axios({ method, url, data })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-right',
            title: `Produto atualiado!`,
            text: res.mensagem,
            type: 'success'
          })
          // this.fecharModal()
          this.reloadForm()
          this.clearDados()
        })
        .catch(res => {
          this.saving = false
          this.$notify({
            group: 'top-center',
            type: 'error',
            title: 'Erro ao cadastrar produto!',
            text: `${res.response.data.mensagem}`
          })
        })
      this.saving = false
    },
    fecharModal() {
      this.$bvModal.hide('detalhes-produto')
    }
  },
  computed: {
    isEditing() {
      return this.produtoId !== undefined
    }
  }
}
//mostrar paginas varias if multivias true
</script>
<style scoped></style>
