import {getCurrentUser} from "../utils";

export default {
    methods:{
        checkFuncionalidade(id){
            let funcionalidades = getCurrentUser()
                .perfil
                .funcionalidades
                .map(f =>{
                    return parseInt(f.id)
                })
            return funcionalidades.includes(id)
        },
    }
}